import React, { Fragment } from "react";
import { Input } from "antd";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import Text from "../text";
import colors from "../../styles/colors";
import theme from "../../theme";

const useStyles = createUseStyles({
  root: {
    background: colors.white["light-shade-1"],
    minHeight: 42,
    textIndent: 12,
    padding: 0,
    "& input, & .ant-input-group-addon": {
      background: colors.white.shade1,
    },
  },
  passwordRoot: {
    background: colors.white.shade1,
    minHeight: 42,
    textIndent: 12,
    padding: 0,
    paddingRight: 12,
    "& input, & .ant-input-group-addon": {
      background: colors.white.shade1,
    },
  },
});

const Textfield = ({
  suffix,
  label,
  inputLabel,
  disabled,
  rootClass,
  type = "input",
  ...props
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      {inputLabel && (
        <Text
          style={
            disabled
              ? {
                  marginBottom: 8,
                  color: theme.neutral.color["light-shade-1"],
                }
              : {
                  marginBottom: 8,
                  color: theme.neutral.color["light-shade-1"],
                }
          }
        >
          {inputLabel}
        </Text>
      )}
      {type !== "password" ? (
        <Input
          disabled={disabled}
          className={clsx([classes.root, rootClass])}
          prefix={label ? <div style={{ marginRight: 4 }}>{label}</div> : ""}
          suffix={suffix ? <div style={{ marginRight: 16 }}>{suffix}</div> : ""}
          {...props}
        />
      ) : (
        <Input.Password
          disabled={disabled}
          className={clsx([classes.passwordRoot, rootClass])}
          prefix={label ? <div style={{ marginRight: 4 }}>{label}</div> : ""}
          suffix={suffix ? <div style={{ marginRight: 16 }}>{suffix}</div> : ""}
          {...props}
        />
      )}
    </Fragment>
  );
};

export default Textfield;
