import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import contact_bg from "../../assets/images/contact_bg.png";
import { Col, Row } from "antd";
import Text from "../../components/text";
import theme from "../../theme";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { isMobile, isTablet } from "react-device-detect";
import Prismic from "prismic-javascript";
import { apiEndpoint } from "../../prismic-configuration";
import Page from "../../components/page";
import TSFooter from "../../components/footer";
import Loader from "../../components/loader";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import ContactForm from "../product/ContactForm";

const position = [11.0088072, 76.9647911];

function Contact() {
  const [data, setdata] = useState(undefined);
  const [loading, setloading] = useState(true);

  const getPageData = async () => {
    Prismic.api(apiEndpoint).then((api) => {
      api
        .query(Prismic.Predicates.at("document.type", "contact"))
        .then((response) => {
          if (response && response.results && response.results.length > 0) {
            const { data } = response.results[0];
            console.log(data, "data");
            setdata(data);
            setloading(false);
          }
        });
    });
  };

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <>
      {!data ? (
        <Loader />
      ) : (
        <Page>
          <div
            style={{
              backgroundImage: `url(${contact_bg})`,
              height: isMobile || isTablet ? "fit-content" : "100vh",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Header />
            <div style={{ padding: isMobile ? 30 : 100 }}>
              <Row justify="space-between" gutter={[16, 64]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <div style={{ textAlign: isMobile ? "center" : "" }}>
                    <Row gutter={[8, 16]}>
                      <Col span={24}>
                        <Text
                          variant="title"
                          style={{
                            color: theme.accent.color["secondary-shade-1"],
                          }}
                        >
                          {loading
                            ? loading
                            : data &&
                              data.header[0] &&
                              data.header[0].title[0].text}
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Text
                          variant="body3"
                          style={{
                            color: theme.neutral.color["light-shade-1"],
                          }}
                        >
                          {loading
                            ? loading
                            : data &&
                              data.header[0] &&
                              data.header[0].sub_text[0].text}
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Text
                          variant="body3"
                          style={{
                            maxWidth: 460,
                            color: theme.accent.color["secondary-shade-1"],
                          }}
                        >
                          {loading
                            ? loading
                            : data &&
                              data.header[0] &&
                              data.header[0].description[0].text}
                        </Text>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <div
                    style={{
                      background: " #0D173A",
                      boxShadow: "4px 8px 20px rgba(0, 0, 0, 0.15)",
                      borderRadius: 10,
                      border: "1px solid #FFDC64",
                      overflow: "hidden",
                    }}
                  >
                    <ContactForm padding={25} />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div
            style={{
              marginTop: isMobile || isTablet ? 0 : 20,
              marginBottom: 200,
              padding: isMobile || isTablet ? 30 : 100,
            }}
          >
            <Row gutter={[16, 32]} align="middle" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                <Row align="middle" justify="center">
                  <Col>
                    <div
                      style={{
                        background: "#FFDC64",
                        width: 192,
                        height: 217,
                        position: "absolute",
                        top: -20,
                        left: -20,
                        borderRadius: 4,
                      }}
                    />
                    <MapContainer
                      center={position}
                      zoom={13}
                      scrollWheelZoom={false}
                      style={{
                        width: isMobile ? 225 : 555,
                        height: isMobile ? 150 : 375,
                      }}
                    >
                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker position={position}></Marker>
                    </MapContainer>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Row gutter={[16, 16]} align="middle" justify="center">
                    <Col span={24}>
                      <Text
                        variant="headline"
                        style={{
                          color: theme.primary.color["primary-shade-1"],
                          textAlign: isMobile || isTablet ? "center" : "",
                        }}
                      >
                        {loading
                          ? loading
                          : data &&
                            data.contact[0] &&
                            data.contact[0].title[0].text}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text
                        variant="body2"
                        style={{
                          color: theme.primary.color["primary-shade-1"],
                          textAlign: isMobile || isTablet ? "center" : "",
                        }}
                      >
                        {loading
                          ? loading
                          : data &&
                            data.contact[0] &&
                            data.contact[0].address_line_1[0].text}
                        {loading
                          ? loading
                          : data &&
                            data.contact[0] &&
                            data.contact[0].address_line_2[0].text}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Row
                        align="middle"
                        gutter={16}
                        justify={isMobile || isTablet ? "center" : ""}
                      >
                        <Col>
                          <div
                            style={{
                              width: 32,
                              height: 32,
                              borderRadius: "50%",
                              background:
                                theme.primary.color["primary-shade-1"],
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <PhoneOutlined
                              style={{
                                color: theme.accent.color["secondary-shade-1"],
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <Text
                            variant="body2"
                            style={{
                              color: theme.primary.color["primary-shade-1"],
                            }}
                          >
                            {loading
                              ? loading
                              : data &&
                                data.contact[0] &&
                                data.contact[0].contact_number[0].text}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row
                        align="middle"
                        justify={isMobile || isTablet ? "center" : ""}
                        gutter={16}
                      >
                        <Col>
                          <div
                            style={{
                              width: 32,
                              height: 32,
                              borderRadius: "50%",
                              background:
                                theme.primary.color["primary-shade-1"],
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MailOutlined
                              style={{
                                color: theme.accent.color["secondary-shade-1"],
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <Text
                            variant="body2"
                            style={{
                              color: theme.primary.color["primary-shade-1"],
                            }}
                          >
                            {loading
                              ? loading
                              : data &&
                                data.contact[0] &&
                                data.contact[0].contact_email[0].text}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <TSFooter />
        </Page>
      )}
    </>
  );
}

export default Contact;
