import React from "react";
import Image from "../image";
import Fade from "react-reveal/Fade";

function BrandImage({ image }) {
  return (
    <div
      style={{
        background: "#FFDC64",
        border: " 0.5px solid #CCD0C7",
        boxShadow: "-3px 6px 8px -1px rgba(52, 66, 30, 0.08)",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 200,
        height: 200,
      }}
    >
      <Fade>
        <Image
          src={image}
          width={144}
          height={144}
          style={{
            objectFit: "contain",
          }}
        />
      </Fade>
    </div>
  );
}

export default BrandImage;
