import { Col, Row } from "antd";
import React from "react";
import theme from "../../theme";
import Text from "../text";

function Tab({ handleClick, state, data }) {
  return (
    <div style={{ display: "flex" }}>
      <Row gutter={32}>
        {data &&
          data.map((item) => {
            return (
              <Col onClick={() => handleClick(item)}>
                <Text
                  variant="body2"
                  style={{
                    color: theme.neutral.color["light-shade-1"],
                    cursor: "pointer",
                    borderBottom: state === item ? "4px solid #FFDC64" : "",
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    paddingBottom: 8,
                  }}
                >
                  {item}
                </Text>
              </Col>
            );
          })}
      </Row>
    </div>
  );
}

export default Tab;
