import { Row, Layout, Col } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import Image from "../../components/image";
import logo from "../../assets/images/logo.svg";
import Text from "../../components/text";
import theme from "../../theme";
import { PushpinOutlined } from "@ant-design/icons";

const { Footer } = Layout;

const useStyles = createUseStyles(() => ({
  content: {
    "& .ant-layout-content": {
      background: "#fff !important",
    },
  },
  footer: {
    "& .ant-layout-footer": {
      background: "#F3F3F3",
      padding: 0,
    },
  },

  link: {
    position: "relative",
    "& .linkLine": {
      width: "0%",
      transition: "width .6s",
      position: "absolute",
      bottom: 0,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      marginTop: 5,
    },

    "&:hover .linkLine": {
      background: "#FFDC64",
      width: "100%",
      maxWidth: 100,
      height: 4,
    },
  },
}));

const productList = [
  {
    name: "Pipes",
    link: "?p=pipes",
  },
  {
    name: "Valves",
    link: "?p=valves",
  },
  {
    name: "Water meters",
    link: "?p=water-meters",
  },
  {
    name: "Fire fightings",
    link: "?p=fire-fightings",
  },
];

const TSFooter = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Footer>
        <div style={{ padding: "20px 30px" }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Image src={logo} width={75} height={69} preview={false} />
                </Col>
                <Col span={24}>
                  <Text
                    variant="subhead"
                    style={{
                      color: theme.primary.color["primary-shade-1"],
                    }}
                  >
                    For Contact
                  </Text>
                </Col>
                <Col span={24}>
                  <Text
                    variant="body2"
                    style={{
                      color: theme.text.color["dark-shade-0"],
                    }}
                  >
                    (P): 866-735-3008, 989-410-5887
                  </Text>
                </Col>
                <Col span={24}>
                  <Text
                    variant="body2"
                    style={{
                      color: theme.text.color["dark-shade-0"],
                    }}
                  >
                    0422- 2234737
                  </Text>
                </Col>
                <Col span={24}>
                  <Text
                    variant="body2"
                    style={{
                      color: theme.text.color["dark-shade-0"],
                    }}
                  >
                    Email : hello@tahersteels.in
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Text
                    variant="subhead"
                    style={{
                      color: theme.primary.color["primary-shade-1"],
                    }}
                  >
                    Products
                  </Text>
                </Col>
                {productList.map((item) => (
                  <Col span={24} key={item.name}>
                    <Text
                      variant="body2"
                      style={{
                        color: theme.text.color["dark-shade-0"],
                        cursor: "pointer",
                      }}
                      className={classes.link}
                      onClick={() =>
                        (window.location.href = `/products${item.link}`)
                      }
                    >
                      {item.name}
                      <div style={{ paddingTop: 5 }}>
                        <div className="linkLine"></div>
                      </div>
                    </Text>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Text
                    variant="subhead"
                    style={{
                      color: theme.primary.color["primary-shade-1"],
                      cursor: "pointer",
                    }}
                  >
                    Company
                  </Text>
                </Col>
                <Col span={24}>
                  <Text
                    variant="body2"
                    style={{
                      color: theme.text.color["dark-shade-0"],
                      cursor: "pointer",
                    }}
                    onClick={() => (window.location.href = "/about")}
                    className={classes.link}
                  >
                    About
                    <div style={{ paddingTop: 5 }}>
                      <div className="linkLine"></div>
                    </div>
                  </Text>
                </Col>
                <Col span={24}>
                  <Text
                    variant="body2"
                    style={{
                      color: theme.text.color["dark-shade-0"],
                      cursor: "pointer",
                    }}
                    onClick={() => (window.location.href = "/contact")}
                    className={classes.link}
                  >
                    Contact us
                    <div style={{ paddingTop: 5 }}>
                      <div className="linkLine"></div>
                    </div>
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Text
                    variant="subhead"
                    style={{
                      color: theme.primary.color["primary-shade-1"],
                    }}
                  >
                    Address
                  </Text>
                </Col>
                <Col span={24}>
                  <Text
                    variant="body2"
                    style={{
                      color: theme.text.color["dark-shade-0"],
                      maxWidth: "50%",
                    }}
                  >
                    <span>
                      <PushpinOutlined />
                    </span>
                    258, Rangan Street, Katoor, Coimbatore, 641009, Tamil Nadu
                    India
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          style={{
            background: theme.primary.color["primary-shade-1"],
            width: "100%",
            height: 55,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            variant="body2"
            style={{
              color: theme.neutral.color["light-shade-1"],
              textAlign: "center",
            }}
          >
            © Taher Steels | Proudly Powered by HashDhronas
          </Text>
        </div>
      </Footer>
    </div>
  );
};

export default TSFooter;
