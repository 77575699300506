import { Route, Switch, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import Homepage from "../pages/home";
import Layouts from "../components/layout";
import Product from "../pages/product";
import ProductDetail from "../pages/product-detail";
import About from "../pages/about";
import Contact from "../pages/contact";

const history = createBrowserHistory();

const Routing = () => {
  return (
    <Router history={history}>
      <Switch>
        <Layouts>
          <Route path="/" component={Homepage} exact />
          <Route path="/products" component={Product} />
          <Route path="/product-detail" component={ProductDetail} exact />
          <Route path="/about" component={About} exact />
          <Route path="/contact" component={Contact} exact />
        </Layouts>
      </Switch>
    </Router>
  );
};

export default Routing;
