import React from "react";
import theme from "../../theme";
import Image from "../image";
import Text from "../text";

function Title({ titleImage, title }) {
  return (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            background: "#FFDC64",
            boxShadow: "1px 2px 8px 5px rgba(52, 66, 30, 0.08)",
            borderRadius: 4,
            width: 120,
            height: 120,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image src={titleImage} width={72} height={72} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            variant="headline"
            style={{
              color: theme.primary.color["primary-shade-1"],
              marginLeft: 30,
            }}
          >
            {title}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default Title;
