import { Form, Input, Button, Row, Col } from "antd";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import Text from "../../components/text";
import Textfield from "../../components/textfield";
import contactImg from "../../assets/images/contact.svg";

const useStyles = createUseStyles({
  textField: {
    border: "1px solid #FFDC64",
    background: "#0d173a !important",
    minHeight: 48,
    borderRadius: 4,
    color: "#fff",
    "&.ant-input": {
      background: "#0d173a !important",
    },
    "&:hover,&:focus": {
      border: "1px solid #FFDC64",
      borderColor: "#FFDC64 !important",
    },
    "&::placeholder": {
      color: "#FFDC64",
      opacity: 0.5 /* Firefox */,
    },

    "&:-ms-input-placeholder": {
      /* Internet Explorer 10-11 */ color: "#FFDC64",
    },

    "&::-ms-input-placeholder": {
      /* Microsoft Edge */ color: "#FFDC64",
    },
  },
});

const url = "https://remoting.hashdhronas.com/send/mail";

export default function ContactForm({
  title,
  productId,
  padding = 65,
  style = {},
  formStyle = {},
}) {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (v) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
        "My-Custom-Header": "foobar",
      },
      body: JSON.stringify({
        product: productId,
        customer: v.customer,
        email: v.email,
        phone: v.phone,
        company: v.company,
        description: v.description,
        admin: "hello@tahersteels.in",
      }),
    };
    setLoading(true);
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        alert("Opps their are something wrong!");
      });
  };

  return (
    <div
      style={{
        padding,
        background: success ? "#F3F3F3" : "#0D173A",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...style,
      }}
    >
      {success ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={contactImg} alt="handshake" style={{ width: "100%" }} />
          <Text
            style={{ marginTop: 31, fontFamily: "Raleway" }}
            variant="headline"
          >
            Contact you soon !
          </Text>
          <div style={{ maxWidth: 400, marginTop: 25 }}>
            <Text
              style={{
                fontSize: 14,
                lineHeight: "19px",
                color: "#0E1A40",
                fontFamily: "Raleway",
              }}
            >
              Thanks for connecting, we will reach you out very soon.
            </Text>
          </div>
        </div>
      ) : (
        <>
          {title && (
            <Text
              variant="headline"
              style={{ color: "#FFDC64", width: "100%" }}
            >
              {title}
            </Text>
          )}
          <Form
            onFinish={handleSubmit}
            wrapperCol={24}
            labelCol={24}
            style={{ ...formStyle }}
          >
            <Row
              justify="space-between"
              gutter={[32, 25]}
              style={{
                marginLeft: 0,
                marginRight: 0,
                width: "100%",
                maxWidth: 512,
              }}
            >
              <Col style={{ marginLeft: 0, marginRight: 0 }} xs={24} lg={12}>
                <Form.Item
                  name="customer"
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: "Name is required!" }]}
                >
                  <Textfield
                    className={classes.textField}
                    placeholder="Your name"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col style={{ marginLeft: 0, marginRight: 0 }} xs={24} lg={12}>
                <Form.Item
                  name="email"
                  style={{ width: "100%", margin: 0 }}
                  rules={[
                    { required: true, message: "Email is required!" },
                    { type: "email", message: "Email is invalid" },
                  ]}
                >
                  <Textfield
                    placeholder="Email"
                    className={classes.textField}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col style={{ marginLeft: 0, marginRight: 0 }} xs={24} lg={12}>
                <Form.Item style={{ width: "100%", margin: 0 }} name="phone">
                  <Textfield
                    placeholder="Phone Number"
                    className={classes.textField}
                    style={{ width: "100%" }}
                    rules={[
                      { required: true, message: "Phone number is required!" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col style={{ marginLeft: 0, marginRight: 0 }} xs={24} lg={12}>
                <Form.Item style={{ width: "100%", margin: 0 }} name="company">
                  <Textfield
                    placeholder="Company"
                    className={classes.textField}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="description">
                  <Input.TextArea
                    placeholder="Description"
                    className={classes.textField}
                    style={{ height: 116 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 50,
              }}
            >
              <Button
                style={{
                  width: 200,
                  background: "#FFDC64",
                  height: 48,
                  border: "none",
                }}
                htmlType="submit"
                loading={loading}
              >
                <Text
                  variant="body2"
                  style={{ fontWeight: 600, display: "inline" }}
                >
                  Send
                </Text>
              </Button>
            </div>
          </Form>
        </>
      )}
    </div>
  );
}
