const pixels = {
  spacing: {
    xxxxs: 8,
    xxs: 16,
    xs: 24,
    sm: 32,
    md: 48,
    lg: 64,
    xl: 80,
    xxl: 96,
    xxxl: 128,
    xxxxl: 160,
  },
  lineHeight: {
    xxxs: 22,
    xxs: 17,
    xs: 24,
    sm: 27,
    md: 28,
    lg: 30,
    xl: 32,
    xxl: 36,
    xxxl: 42,
    xxxxl: 56,
  },
  fontSize: {
    xs: 11,
    sm: 12,
    md: 13,
    lg: 14,
    xl: 15,
    xxl: 16,
    xxll:18,
    xxxl: 20,
    xxxxl: 24,
    xxxxxl: 40,
    xxxxxxl: 48,
  },
};

export default pixels;
