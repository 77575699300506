import React, { useState } from "react";
import Title from "../../components/title";
import { Col, Row, Select } from "antd";
import ProductCard from "../../components/product-card";
import TextField from "../../components/textfield";
import { createUseStyles } from "react-jss";
import Pipes from "../../assets/images/category/pipe.svg";
import PipeFittings from "../../assets/images/category/pipe_fittings.svg";
import Valve from "../../assets/images/category/valve.svg";
import WaterMeter from "../../assets/images/category/water-meter.svg";
import FireFittings from "../../assets/images/category/fire-fittings.svg";

const getCategoryLogo = (type) => {
  console.log(type);
  switch (type) {
    case "Pipes":
      return Pipes;
    case "Valves":
      return Valve;
    case "Water meters":
      return WaterMeter;
    case "Fire fightings":
      return FireFittings;
    case "Pipe Fittings":
      return PipeFittings;
    default:
      break;
  }
};

const useStyles = createUseStyles({
  select: {
    width: 168,
    "& .ant-select-selector": {
      height: "48px !important",
      display: "flex",
      alignItems: "center",
      fontFamily: "Raleway",
      "& input": {
        fontFamily: "Raleway",
      },
    },
  },
});

const bandList = [
  "Jindal steel",
  "Tata steel",
  "Zoloto valves",
  "Kartar",
  "Kranti water meters",
  "Racer valves",
  "Normex",
  "Matrix",
];

export default function AllProduct({ data, currentTab }) {
  const classes = useStyles();
  const [filterText, setFilterText] = useState("");
  const [filterBrand, setFilterBrand] = useState();

  return (
    <div>
      {data && currentTab && (
        <div style={{ padding: 30 }}>
          <Row
            align="middle"
            style={{ marginTop: 67, display: "flex" }}
            gutter={[37, 30]}
          >
            <Col xs={24} md={8}>
              <Title
                title={
                  currentTab &&
                  data &&
                  data[currentTab] &&
                  data[currentTab].length > 0 &&
                  data[currentTab][0].product_category
                }
                titleImage={
                  data &&
                  currentTab &&
                  data[currentTab] &&
                  data[currentTab].length > 0 &&
                  getCategoryLogo(data[currentTab][0].product_category)
                }
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={5}
              style={{ marginLeft: "auto" }}
            >
              <TextField
                placeholder="Search by name"
                value={filterText}
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
                style={{
                  // maxWidth: 326,
                  marginLeft: "auto",
                  // marginRight: 37,
                  height: 48,
                  fontFamily: "Raleway",
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={5}>
              <Select
                placeholder="Search by brand"
                allowClear
                className={classes.select}
                value={filterBrand}
                onChange={(v) => setFilterBrand(v)}
                style={{ width: "100%" }}
              >
                {bandList.map((item) => (
                  <Select.Option key={item} vlaue={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <div style={{ marginTop: 70 }}>
            <Row
              align="middle"
              justify="center"
              gutter={[64, 64]}
              style={{ margin: 0 }}
            >
              {data &&
                currentTab &&
                data[currentTab] &&
                data[currentTab]
                  .map((item) => {
                    const { product_image, name, description, brand } = item;
                    console.log();
                    if (
                      filterText.length > 0 &&
                      !name[0].text
                        .toLocaleLowerCase()
                        .includes(filterText.toLocaleLowerCase())
                    ) {
                      return null;
                    }
                    if (filterBrand && brand !== filterBrand) {
                      return null;
                    }
                    return (
                      <Col key={item.uid[0].text}>
                        <ProductCard
                          variant="outlined"
                          productName={name[0].text}
                          //productPrice="$12.99"
                          id={item.uid[0].text}
                          brand={brand}
                          productImage={product_image.url}
                          make={
                            description && description.length > 0
                              ? description[0].text
                              : ""
                          }
                        />
                      </Col>
                    );
                  })
                  .filter((item) => item)}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}
