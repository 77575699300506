const fonts = {
  Butler: {
    name: "Butler",
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
    },
  },
  OpenSans: {
    name: "Raleway",
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
    },
  },
};

export default fonts;
