import { Row, Col } from "antd";
import React, { useEffect, useState, Fragment } from "react";
import Carousel from "react-multi-carousel";
import about_bg from "../../assets/images/about-bg.png";
import Header from "../../components/header";
import theme from "../../theme";
import Text from "../../components/text";
import Image from "../../components/image";
import { isMobile, isTablet } from "react-device-detect";
import { apiEndpoint } from "../../prismic-configuration";
import Prismic from "prismic-javascript";
import Page from "../../components/page";
import Loader from "../../components/loader";
import TSFooter from "../../components/footer";
import OurVison from "./OurVison";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function About() {
  const [data, setdata] = useState(undefined);
  const [loading, setloading] = useState(true);

  const getPageData = async () => {
    Prismic.api(apiEndpoint).then((api) => {
      api
        .query(Prismic.Predicates.at("document.type", "about"))
        .then((response) => {
          if (response && response.results && response.results.length > 0) {
            const { data } = response.results[0];
            setdata(data);
            setloading(false);
          }
        });
    });
  };

  useEffect(() => {
    getPageData();
  }, [data]);

  return (
    <>
      {!data ? (
        <Loader />
      ) : (
        <Page>
          {data && (
            <Fragment>
              <div
                style={{
                  backgroundImage: `url(${about_bg})`,
                  height: "80vh",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <Header />
                <div
                  style={{ padding: isMobile || isTablet ? 30 : "50px 150px" }}
                >
                  <Row gutter={[16, 64]} align="middle" justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <div
                        style={{
                          textAlign: isMobile || isTablet ? "center" : "",
                        }}
                      >
                        <Row gutter={[8, 16]}>
                          <Col span={24}>
                            <Text
                              variant="title"
                              style={{
                                color: theme.accent.color["secondary-shade-1"],
                              }}
                            >
                              {loading
                                ? loading
                                : data &&
                                  data.header[0] &&
                                  data.header[0].title[0].text}
                            </Text>
                          </Col>
                          <Col span={24}>
                            <Text
                              variant="body3"
                              style={{
                                color: theme.neutral.color["light-shade-1"],
                              }}
                            >
                              {loading
                                ? loading
                                : data &&
                                  data.header[0] &&
                                  data.header[0].description[0].text}
                            </Text>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Row align="middle" justify="center">
                        <Col>
                          <div
                            style={{
                              background: "#FFDC64",
                              width: 192,
                              height: 217,
                              position: "absolute",
                              top: -20,
                              left: -20,
                              borderRadius: 4,
                            }}
                          />
                          <Image
                            src={
                              loading
                                ? loading
                                : data &&
                                  data.header[0] &&
                                  data.header[0].cover_image.url
                            }
                            width={isMobile || isTablet ? 300 : 356}
                            height={isMobile || isTablet ? 300 : 411}
                            style={{ objectFit: "contain" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* About */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: isMobile || isTablet ? "100%" : 960,
                  margin: "auto",
                  padding: isMobile || isTablet ? 30 : 100,
                  marginTop: isMobile || isTablet ? 250 : 0,
                }}
              >
                <Row gutter={[10, 12]}>
                  {data &&
                    data.introduction &&
                    data.introduction[0].message &&
                    data.introduction[0].message.map((item) => {
                      const { text = "" } = item;
                      return (
                        <Col span={24}>
                          <Text
                            variant="body2"
                            style={{ color: "#000", textAlign: "center" }}
                          >
                            {loading ? loading : text}
                          </Text>
                        </Col>
                      );
                    })}
                </Row>
              </div>

              {/* Our Story */}
              <div
                style={{
                  padding: isMobile || isTablet ? 30 : 100,
                  background: "#F3F3F3",
                }}
              >
                <Text
                  variant="headline"
                  style={{
                    color: theme.primary.color["primary-shade-1"],
                    marginBottom: 30,
                    textAlign: "center",
                  }}
                >
                  {loading
                    ? loading
                    : data &&
                      data.story[0] &&
                      data.story[0].title &&
                      data.story[0].title[0].text}
                </Text>
                <div
                  style={{
                    maxWidth: isMobile || isTablet ? "100%" : 960,
                    margin: "auto",
                  }}
                >
                  {data &&
                    data.story &&
                    data.story[0].message &&
                    data.story[0].message.map((item) => {
                      const { text = "" } = item;
                      return (
                        <Text
                          variant="body2"
                          style={{
                            color: "#000",
                            textAlign: "center",
                            marginBottom: 12,
                          }}
                        >
                          {loading ? loading : text}
                        </Text>
                      );
                    })}
                </div>

                <div
                  style={{
                    width: "100%",
                    maxWidth: 960,
                    margin: "auto",
                    marginTop: 76,
                  }}
                >
                  <Carousel responsive={responsive} swipeable={true}>
                    {data &&
                      data.clients &&
                      data.clients.map((item) => {
                        const { logo } = item;
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              src={logo.url}
                              width={isMobile ? 264 : 109}
                              height={isMobile ? 264 : 110}
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        );
                      })}
                  </Carousel>
                </div>
              </div>

              <OurVison data={data} />
            </Fragment>
          )}
          <TSFooter />
        </Page>
      )}
    </>
  );
}

export default About;
