import Pixels from "./pixels";
import Color from "./colors";
import theme from "../theme";

const typography = {
  title: {
    color: Color.black["dark-shade-1"],
    lineHeight: "58px",
    fontSize: Pixels.fontSize.xxxxxl,
    fontFamily: theme.primary.font.primary.name,
    fontWeight: theme.primary.font.primary.weight.medium,
  },

  headline: {
    color: Color.black["dark-shade-1"],
    lineHeight: "40px",
    fontSize: Pixels.fontSize.xxxxxl,
    fontFamily: theme.primary.font.primary.name,
    fontWeight: theme.primary.font.primary.weight.bold,
  },

  subhead: {
    color: Color.black["dark-shade-1"],
    lineHeight: "28px",
    fontSize: Pixels.fontSize.xxxl,
    fontFamily: theme.primary.font.primary.name,
    fontWeight: theme.primary.font.primary.weight.bold,
  },

  // 24px
  body0: {
    color: Color.black["dark-shade-1"],
    lineHeight: "28px",
    fontSize: Pixels.fontSize.xxxxl,
    fontFamily: theme.primary.font.secondary.name,
    fontWeight: theme.primary.font.secondary.weight.medium,
  },

  // 20px
  body1: {
    color: Color.black["dark-shade-1"],
    lineHeight: "28px",
    fontSize: Pixels.fontSize.xxxl,
    fontFamily: theme.primary.font.secondary.name,
    fontWeight: theme.primary.font.secondary.weight.medium,
  },

  // 18px
  body2: {
    color: Color.black["dark-shade-1"],
    lineHeight: "28px",
    fontSize: Pixels.fontSize.xxll,
    fontFamily: theme.primary.font.secondary.name,
    fontWeight: theme.primary.font.secondary.weight.medium,
  },

  // 16px
  body3: {
    color: Color.black["dark-shade-1"],
    lineHeight: "28px",
    fontSize: Pixels.fontSize.xxl,
    fontFamily: theme.primary.font.secondary.name,
    fontWeight: theme.primary.font.secondary.weight.bold,
  },

  // 12px
  caption: {
    color: Color.black["dark-shade-1"],
    lineHeight: "28px",
    fontSize: Pixels.fontSize.sm,
    fontFamily: theme.primary.font.secondary.name,
    fontWeight: theme.primary.font.secondary.weight.bold,
  },
};

export default typography;
