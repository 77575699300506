import colors from "../src/styles/colors";
import font from "../src/styles/font";

const theme = {
  primary: {
    color: colors.indigo,
    font: {
      primary: font.Butler,
      secondary: font.OpenSans,
    },
  },
  accent: {
    color: colors.yellow,
    font: {
      primary: font.Butler,
      secondary: font.OpenSans,
    },
  },
  neutral: {
    color: colors.white,
    font: {
      primary: font.Butler,
      secondary: font.OpenSans,
    },
  },
  text: {
    color: colors.black,
    font: {
      primary: font.Butler,
      secondary: font.OpenSans,
    },
  },
  states: {
    success: colors.green,
    loading: colors.yellow,
    failure: colors.rose,
  },
};

export default theme;
