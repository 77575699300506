import React, { useState, useEffect, useCallback } from "react";
import Header from "../../components/header";
import product_bg from "../../assets/images/product_bg.png";
import Tab from "../../components/tab";
import { apiEndpoint } from "../../prismic-configuration";
import Prismic from "prismic-javascript";
import Page from "../../components/page";
import Loader from "../../components/loader";
import TSFooter from "../../components/footer";
import AllProduct from "./AllProduct";
import ProductDetails from "./ProductDetails";
import { Route, Switch } from "react-router-dom";

function Product() {
  const [data, setdata] = useState(undefined);
  const [categoryHeaders, setCategoryHeaders] = useState([]);
  const [currentTab, setTab] = useState(undefined);

  const formData = useCallback(
    (data) => {
      const { categories, products } = data;
      categories.forEach((element) => {
        const { category = [] } = element;
        const { text = "" } = category[0];
        let headers = categoryHeaders;
        headers.push(text);
        setCategoryHeaders(headers);
        const result = products.reduce(function (r, a) {
          r[a.product_category] = r[a.product_category] || [];
          r[a.product_category].push(a);
          return r;
        }, Object.create(null));

        const params = new URLSearchParams(window.location.search);
        const name = params.get("p");
        const inList =
          name &&
          headers.find((item) => item.toLowerCase() === name.replace("-", " "));
        if (inList) {
          setTab(inList);
        } else {
          setTab(categories[0].category[0].text);
        }
        setdata(result);
      });
    },
    [categoryHeaders]
  );

  const getPageData = useCallback(async () => {
    Prismic.api(apiEndpoint).then((api) => {
      api
        .query(Prismic.Predicates.at("document.type", "products"))
        .then((response) => {
          if (response && response.results && response.results.length > 0) {
            const { data } = response.results[0];
            formData(data);
          }
        });
    });
  }, [formData]);

  useEffect(() => {
    getPageData();
  }, [getPageData]);

  return (
    <>
      {!data ? (
        <Loader />
      ) : (
        <Page>
          <div
            style={{
              backgroundImage: `url(${product_bg})`,
              height: "80vh",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Header />
            <div style={{ marginTop: 200, padding: 30 }}>
              {currentTab && categoryHeaders && (
                <Tab
                  data={categoryHeaders}
                  state={currentTab}
                  handleClick={(tab) => {
                    setTab(tab);
                  }}
                />
              )}
            </div>
          </div>
          <Switch>
            <Route path="/products" exact>
              <AllProduct data={data} currentTab={currentTab} />
            </Route>
            <Route path="/products/product/:productId" exact>
              <ProductDetails data={data} setTab={setTab} />
            </Route>
          </Switch>
          {/* <AllProduct data={data} currentTab={currentTab} /> */}
          <TSFooter />
        </Page>
      )}
    </>
  );
}

export default Product;
