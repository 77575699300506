import { Col, Row } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";

import Text from "../../components/text";
import ContactForm from "./ContactForm";

const useStyles = createUseStyles({
  goBackButton: {
    background: "none",
    boxShadow: "none",
    border: "none",
    cursor: "pointer",
  },
});

export default function ProductDetails({ data, setTab }) {
  const classes = useStyles();
  const { productId } = useParams("productId");
  const dataKeys = Object.keys(data);
  let product;
  dataKeys.forEach((key) => {
    const find = data[key].find((item) => item.uid[0].text === productId);
    if (find) {
      product = find;
      setTab(key);
    }
  });

  return (
    <>
      <Row
        gutter={[{ lg: 56 }, 40]}
        style={{ padding: "37px 65px", marginLeft: 0, marginRight: 0 }}
      >
        <Col span={24}>
          <button
            onClick={() => {
              window.location.href = "/products";
            }}
            className={classes.goBackButton}
          >
            <Text variant="body2">Go back</Text>
          </button>
        </Col>
        <Col
          style={{
            maxWidth: 461,
            height: 423,
          }}
          xs={24}
        >
          <div
            style={{
              borderRadius: 4,
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={product.product_image.url}
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        </Col>
        <Col xs={24} style={{ maxWidth: 435, paddingTop: 20 }}>
          <Text variant="headline" style={{ marginBottom: 13 }}>
            {product.name[0].text}
          </Text>
          <Text variant="body0" style={{ fontWeight: 600, marginBottom: 24 }}>
            {product.brand}
          </Text>
          <Text variant="body3" style={{ fontWeight: "normal" }}>
            {product.information &&
              product.information.length > 0 &&
              product.information[0].text}
          </Text>
          {/* <pre>{JSON.stringify(product, null, 2)}</pre> */}
        </Col>
      </Row>
      <ContactForm
        title="Business Enquiry Form"
        productId={productId}
        formStyle={{ marginTop: 33 }}
      />
    </>
  );
}
