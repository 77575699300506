import { Col, Row, Carousel as AntCarousel } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import home_bg from "../assets/images/home_bg.png";
import BrandImage from "../components/brand-image";
import Header from "../components/header";
import Image from "../components/image";
import Text from "../components/text";
import theme from "../theme";
import Product from "../components/product";
import { isMobile, isTablet } from "react-device-detect";
import { apiEndpoint } from "../prismic-configuration";
import Prismic from "prismic-javascript";
import Fade from "react-reveal/Fade";
import Page from "../components/page";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Loader from "../components/loader";
import TSFooter from "../components/footer";

const useStyles = createUseStyles(() => ({
  carouselContainer: {
    position: "relative",
    height: isMobile ? 300 : 509,
    width: isMobile ? 264 : 448,
    "& .ant-carousel .slick-dots li": {
      width: 16,
      height: 16,
      borderRadius: "50%",
      background: "#0E1A40",
      border: "1.5px solid #fff",
    },
    "& .ant-carousel .slick-dots li button": {
      width: 16,
      height: 16,
      borderRadius: "50%",
      background: "#0E1A40",
      // border: "1.5px solid #fff",
    },
    "& .ant-carousel .slick-dots li.slick-active": {
      background: "#FFDC64",
    },
    "& .ant-carousel .slick-dots li.slick-active button": {
      background: "#FFDC64",
    },
    "& .ant-carousel .slick-dots": {
      justifyContent: "flex-end",
      right: -40,
    },
  },
}));

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const groupByCategory = (data) => {
  const result = data.reduce(function (r, a) {
    r[a.product_category] = r[a.product_category] || [];
    r[a.product_category].push(a);
    return r;
  }, Object.create(null));
  return result;
};

const Homepage = () => {
  const [category, setCategory] = useState();
  const [data, setdata] = useState(undefined);
  const [loading, setloading] = useState(true);
  const classes = useStyles();

  const getPageData = async () => {
    Prismic.api(apiEndpoint).then((api) => {
      api
        .query(Prismic.Predicates.at("document.type", "home-page"))
        .then((response) => {
          if (response && response.results && response.results.length > 0) {
            const { data } = response.results[0];
            setdata(data);
            setCategory(groupByCategory(data.category));
            setloading(false);
          }
        });
    });
  };

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <>
      {!data ? (
        <Loader />
      ) : (
        <Page>
          <div
            style={{
              backgroundImage: `url(${home_bg})`,
              height: isMobile || isTablet ? "fit-content" : "100vh",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Header />
            <div style={{ padding: 30 }}>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Row align="middle" justify="center">
                    <Col xs={20} sm={20} md={16} lg={16} xl={16} xxl={16}>
                      <div
                        style={{
                          background: "#FFDC64",
                          width: 192,
                          height: 217,
                          position: "absolute",
                          bottom: isMobile ? 9 : -30,
                          left: -30,
                          borderRadius: 4,
                        }}
                      />
                      <div className={classes.carouselContainer}>
                        <AntCarousel autoplay>
                          {data &&
                            data.banner_images &&
                            data.banner_images.map((item) => {
                              const { image = {} } = item;
                              const { url } = image;
                              return (
                                <Fade>
                                  <Image
                                    src={url}
                                    width={isMobile ? 264 : 448}
                                    height={isMobile ? 264 : 509}
                                    style={{ objectFit: "contain" }}
                                  />
                                </Fade>
                              );
                            })}
                        </AntCarousel>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                      textAlign: isMobile || isTablet ? "center" : "",
                    }}
                  >
                    <Row gutter={[8, 16]} align="middle" justify="center">
                      <Col span={24}>
                        <Text
                          variant="title"
                          style={{
                            color: theme.accent.color["secondary-shade-1"],
                          }}
                        >
                          {loading
                            ? loading
                            : data &&
                              data.header[0] &&
                              data.header[0].title[0].text}
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Text
                          variant="body3"
                          style={{
                            color: theme.neutral.color["light-shade-1"],
                          }}
                        >
                          {loading
                            ? loading
                            : data &&
                              data.header[0] &&
                              data.header[0].description[0].text}
                        </Text>
                      </Col>
                      {/*   <Col span={24}>
                        <Button
                          type="primary"
                          style={{
                            color: theme.primary.color["primary-shade-1"],
                            background: theme.accent.color["secondary-shade-1"],
                            width: 160,
                            height: 48,
                            border: "none",
                            fontFamily: "Raleway",
                            fontSize: 16,
                            fontWeight: 600,
                          }}
                        >
                          Click here
                        </Button>
                      </Col>
                     */}
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/* Brands */}
          <div>
            <div
              style={{
                background: "#FFFFFF",
                boxShadow: "1px 2px 8px 5px rgba(52, 66, 30, 0.08)",
                borderTop: "13px solid #FFDC64",
                margin: isMobile || isTablet ? 20 : "0px 120px 0px 120px",
                height: 390,
                marginTop: isMobile || isTablet ? 20 : -60,
                marginBottom: 80,
              }}
            >
              <Text
                variant="headline"
                style={{
                  color: "#34421E",
                  textAlign: "center",
                  marginTop: 25,
                  marginBottom: 25,
                }}
              >
                Brands
              </Text>
              {data && data.brand_products && data.brand_products && (
                <div style={{ padding: 20 }}>
                  <Carousel responsive={responsive} swipeable={true}>
                    {data &&
                      data.brand_products &&
                      data.brand_products.map((item) => {
                        const { logo } = item;
                        const { url = "" } = logo;
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <BrandImage image={url} />
                          </div>
                        );
                      })}
                  </Carousel>
                </div>
              )}
            </div>
          </div>
          {/* Our Pipes */}
          <div style={{ marginBottom: 0 }}>
            {category &&
              Object.keys(category).length > 0 &&
              Object.keys(category).map(function (key, index) {
                const item = category[key] || {};
                const { category_logo = {} } = item[0];
                return (
                  <Product
                    variant={index % 2 !== 0 ? "right" : "left"}
                    title={key}
                    isLast={index === Object.keys(category).length - 1}
                    titleImage={category_logo.url}
                    data={item}
                  />
                );
              })}
          </div>
          <TSFooter />
        </Page>
      )}
    </>
  );
};

export default Homepage;
