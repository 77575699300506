import React from "react";
import classNames from "classnames/bind";
import Styles from "../../styles/typography";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";

const Text = (props) => {
  const {
    classes,
    className,
    variant,
    onClick,
    spanStyle = {},
    ...rest
  } = props;
  const cx = classNames.bind(classes);
  const class_name = cx({
    title: variant === "title",
    headline: variant === "headline",
    subhead: variant === "subhead",
    body0: variant === "body0",
    body1: variant === "body1",
    body2: variant === "body2",
    body3: variant === "body3",
    caption: variant === "caption",
  });

  return (
    <Fade>
      <div className={`${class_name} ${className}`} {...rest}>
        <span style={spanStyle} onClick={onClick}>
          {props.children}
        </span>
      </div>
    </Fade>
  );
};

Text.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.object,
  spanStyle: PropTypes.object,
};

// Same approach for defaultProps too
Text.defaultProps = {
  variant: "body",
  onClick: () => {},
  className: {},
  spanStyle: {},
};

export default withStyles(Styles)(Text);
