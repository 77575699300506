import Prismic from "prismic-javascript";

export const apiEndpoint = "https://taher-steels.cdn.prismic.io/api/v2";
export const accessToken =
  "MC5ZSmpLeHhJQUFDTUFvRzk1.Bu-_vX3vv70d77-977-9Au-_vVnvv71n77-977-977-977-977-977-9Dy0B77-9GgXvv714ZW7vv73vv73vv70b";

// Client method to query documents from the Prismic repo
export const ApiHandler = async (page) => {
  Prismic.api(apiEndpoint).then((api) => {
    api.query(Prismic.Predicates.at("document.type", page)).then((response) => {
      console.log(response.results[0].data);
      return response.results[0].data;
    });
  });
};
