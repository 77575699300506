import { Col, Row } from "antd";
import React from "react";

import Text from "../../components/text";
import theme from "../../theme";

export default function OurVison({ data }) {
  console.log(data.vision, data);
  return (
    <div
      style={{
        width: "100%",
        maxWidth: 950,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 78,
        marginBottom: 100,
        padding: 32,
      }}
    >
      <Row justify="space-between" align="middle">
        <Col style={{ maxWidth: 435 }}>
          <Text
            variant="headline"
            style={{
              color: theme.primary.color["primary-shade-1"],
            }}
          >
            {data.vision[0].title[0].text}
          </Text>
          <Text
            variant="body2"
            style={{
              color: "#000",
              marginTop: 24,
            }}
          >
            {data.vision[0].description[0].text}
          </Text>
        </Col>
        <Col style={{ maxWidth: 266, height: 318 }}>
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 4,
              overflow: "hidden",
            }}
          >
            <img
              alt={data.vision[0].image.alt}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                marginTop: 18,
              }}
              src={data.vision[0].image.url}
            />
          </div>
        </Col>
      </Row>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 67,
        }}
      >
        <Text
          variant="headline"
          style={{
            textAlign: "center",
            color: theme.primary.color["primary-shade-1"],
          }}
        >
          {data.core_values[0]?.text}
        </Text>
        <Text
          variant="body2"
          style={{
            color: "#000",
            marginTop: 24,
            textAlign: "center",
            maxWidth: 612,
          }}
        >
          {data.core_values_description[0]?.text}
        </Text>
      </div>

      <Row
        gutter={[{ lg: 91 }, 100]}
        justify="center"
        style={{ marginTop: 80 }}
      >
        {data.values.map((item) => (
          <Col lg={8}>
          <img src={item.icon.url} style={{ height: 42 }} alt="" />
            <Text
              variant="headline"
              style={{
                color: theme.primary.color["primary-shade-1"],
                fontSize: 16,
              }}
            >
              {item.title[0]?.text}
            </Text>
            <Text
              variant="body2"
              style={{
                color: "#000",
                marginTop: 16,
              }}
            >
              {item.description[0]?.text}
            </Text>
          </Col>
        ))}
      </Row>
    </div>
  );
}
