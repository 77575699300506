import React, { useState } from "react";
import Header from "../../components/header";
import Text from "../../components/text";
import product_bg from "../../assets/images/product_bg.png";
import theme from "../../theme";
import { Col, Row } from "antd";
import Image from "../../components/image";
import Product from "../../components/product";
import fitting from "../../assets/images/fitting.png";
import valve from "../../assets/images/valve.png";
import Page from "../../components/page";
import TSFooter from "../../components/footer";

function ProductDetail() {
  const [count] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);
  return (
    <Page>
      <div
        style={{
          backgroundImage: `url(${product_bg})`,
          height: "80vh",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Header />
        <Text
          variant="title"
          style={{ color: theme.accent.color["secondary-shade-1"] }}
        >
          Product
        </Text>
      </div>
      <div style={{ padding: 60 }}>
        <Row>
          <Col span={12}>
            <Image
              src=""
              width={461}
              height={423}
              style={{ borderRadius: 10 }}
            />
          </Col>
          <Col span={12}>
            <Row gutter={[8, 32]} align="middle" justify="center">
              <Col span={24}>
                <Text variant="title">Product Title</Text>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex" }}>
                  <Image src="" width={48} height={48} />
                  <Text
                    variant="body0"
                    style={{
                      color: theme.primary.color["primary-shade-1"],
                      marginTop: 10,
                      marginLeft: 15,
                    }}
                  >
                    Product Title
                  </Text>
                </div>
              </Col>
              <Col span={24}>
                <Text
                  variant="body3"
                  style={{
                    color: theme.primary.color["primary-shade-1"],
                  }}
                >
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet.
                </Text>
              </Col>
              <Col span={24}>
                <Text
                  variant="body3"
                  style={{
                    color: theme.primary.color["primary-shade-1"],
                  }}
                >
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet.
                </Text>
              </Col>
              <Col span={24}>
                <div style={{ display: "flex" }}>
                  <Text
                    variant="title"
                    style={{
                      color: theme.primary.color["primary-shade-1"],
                    }}
                  >
                    20
                  </Text>
                  <Text
                    variant="caption"
                    style={{
                      color: theme.primary.color["primary-shade-1"],
                      opacity: 0.5,
                    }}
                  >
                    per piece
                  </Text>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* Our Valves */}
      <div style={{ marginBottom: 70, marginTop: 60 }}>
        <Product
          variant="left"
          title="Similar Product like these"
          titleImage={valve}
          data={count}
        />
      </div>
      {/* Our Fittings*/}
      <Product
        variant="left"
        title="Fittings"
        titleImage={fitting}
        data={count}
      />
      <TSFooter />
    </Page>
  );
}

export default ProductDetail;
