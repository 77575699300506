const colors = {
  indigo: {
    "primary-shade-1": "#0E1A40",
    "primary-shade-2": "#4A5370",
    "primary-shade-3": "#878C9F",
    "primary-shade-4": "#878C9F",
  },
  yellow: {
    "secondary-shade-1": "#FFDC64",
    "secondary-shade-2": "#FFE58B",
    "secondary-shade-3": "#FFEDB1",
    "secondary-shade-4": "#FFF6D8",
  },
  semantic: {
    error: "#D63031",
    success: "#00B894",
    warning: "#A2683F",
    information: "#4B90E2",
  },
  white: {
    "light-shade-1": "#fff",
    "light-shade-2": "#E6E6E6",
    "light-shade-3": "#CCCCCC",
    background: "#F7F9FA",
    "neutral-tint-2": "#F2F2F2",
    "neutral-tint-5": "#E8E8E8",
  },
  black: {
    "dark-shade-0": "#000",
    "dark-shade-1": "#333333",
    "dark-shade-2": "#666666",
    "dark-shade-3": "#999999",
    "dark-shade-4": "#CCCCCC",
  },
};

export default colors;
