import { Button, Col, Row } from "antd";
import React from "react";
import theme from "../../theme";
import Image from "../image";
import ProductCard from "../product-card";
import Text from "../text";

const Card = ({ productImage, productName, productDesc }) => {
  return (
    <div>
      <Image
        src={productImage}
        width={263}
        height={269}
        style={{ border: "0.5px solid #CCD0C7", borderRadius: 10 }}
      />
      <Text variant="body2" style={{ color: "#263238", marginTop: 25 }}>
        {productName}
      </Text>
      {productDesc && (
        <Row>
          <Col>
            <Text
              variant="caption"
              style={{
                color: "#263238",
                marginTop: 12,
              }}
            >
              {productDesc}
            </Text>
          </Col>
        </Row>
      )}
    </div>
  );
};

const getProductParam = (item) => {
  const { product_category } = item;
  switch (product_category) {
    case "Fire fightings":
      return "fire-fightings";
    case "Pipes":
      return "pipes";
    case "Water meters":
      return "water-meters";
    case "Valves":
      return "valves";
    case "Pipe Fittings":
      return "pipe-fittings";
    default:
      break;
  }
};

function Product({ titleImage, title, data, variant, isLast }) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          marginBottom: -34,
          marginLeft: variant === "left" ? 54 : 0,
          flexDirection: variant === "right" ? "row-reverse" : "",
          marginRight: variant === "right" ? 60 : 0,
        }}
      >
        <div
          style={{
            background: "#FFDC64",
            boxShadow: "1px 2px 8px 5px rgba(52, 66, 30, 0.08)",
            borderRadius: 4,
            width: 120,
            height: 120,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image src={titleImage} width={72} height={72} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            variant="headline"
            style={{
              color: theme.primary.color["primary-shade-1"],
              marginLeft: variant === "left" ? 30 : 0,
              marginRight: variant === "right" ? 30 : 0,
              marginBottom: 32,
            }}
          >
            {title}
          </Text>
        </div>
      </div>
      <div
        style={{
          background: variant === "left" ? "#0E1A40" : "#F3F3F3",
          marginBottom: isLast ? 0 : 15,
        }}
      >
        <div style={{ padding: "130px 60px" }}>
          {variant === "left" && (
            <Row gutter={[48, 32]} align="middle" justify="center">
              {data.map((item) => {
                const {
                  title = "",
                  product_image = "",
                  description = "",
                } = item;
                return (
                  <Col>
                    <ProductCard
                      variant="borderless"
                      productName={title && title[0].text}
                      productDesc={
                        description && description.length > 0
                          ? description[0].text
                          : ""
                      }
                      productImage={product_image && product_image.url}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
          {variant === "right" && (
            <Row gutter={[48, 32]} align="middle" justify="center">
              {data.map((item) => {
                const { title, product_image, description } = item;
                return (
                  <Col>
                    <Card
                      productName={title[0].text}
                      productDesc={description[0].text}
                      productImage={product_image.url}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{
              background: theme.accent.color["secondary-shade-1"],
              color: theme.primary.color["primary-shade-1"],
              width: 188,
              height: 48,
              borderRadius: 8,
              marginBottom: 40,
              fontFamily: "Raleway",
              fontSize: 16,
              fontWeight: 600,
            }}
            onClick={() =>
              (window.location.href = `/products?p=${getProductParam(data[0])}`)
            }
          >
            View All
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Product;
