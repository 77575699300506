import clsx from "clsx";
import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  root: {
    position: "relative",
  },
});

let scrollHeight = Math.max(
  document.body.scrollHeight,
  document.documentElement.scrollHeight,
  document.body.offsetHeight,
  document.documentElement.offsetHeight,
  document.body.clientHeight,
  document.documentElement.clientHeight
);

const backgroundShaperList = new Array(Math.round(scrollHeight / 300)).fill(0);

console.log(backgroundShaperList);

export default function Page({ children, className, ...props }) {
  const classes = useStyles();
  /* useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }); */

  return (
    <div className={[clsx([classes.root, className])]} {...props}>
      {backgroundShaperList.map((item, index) => {
        const even = index % 2 === 0;
        return (
          <div
            style={{
              position: "absolute",
              top: index * 300,
              left: even ? "" : -150,
              right: even ? 0 : "",

              transform: "translatey(0px)",
              animation: "float 6s ease-in-out infinite",
            }}
          >
            <svg
              width="430"
              height="527"
              viewBox="0 0 430 527"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M395.249 290.585L443.64 184.506L375.493 89.3418L258.954 100.256L210.563 206.335L278.711 301.5L395.249 290.585Z"
                fill="#F2F2F2"
              />
              <path
                d="M73.442 236.106L25.051 342.185L93.198 437.349L209.737 426.435L258.128 320.356L189.98 225.191L73.442 236.106Z"
                fill="#F2F2F2"
              />
            </svg>
          </div>
        );
      })}
      <div
        style={{
          zIndex: 1,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {children}
      </div>
    </div>
  );
}
