import { Layout } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
const { Content } = Layout;

const useStyles = createUseStyles(() => ({
  content: {
    "& .ant-layout-content": {
      background: "#fff !important",
    },
  },
  footer: {
    "& .ant-layout-footer": {
      background: "#F3F3F3",
      padding: 0,
    },
  },
}));

function Layouts({ children }) {
  const classes = useStyles();
  return (
    <div>
      <Layout>
        <div className={classes.content}>
          <Content>{children}</Content>
        </div>
      </Layout>
    </div>
  );
}

export default Layouts;
