import { Button, Col, Row } from "antd";
import React, { Fragment } from "react";
import theme from "../../theme";
import Image from "../image";
import Text from "../text";

function ProductCard({
  productName,
  productPrice,
  variant,
  productImage,
  productDesc,
  make,
  id,
}) {
  return (
    <Fragment>
      {variant === "borderless" && (
        <div style={{ width: 264 }}>
          <Image src={productImage} width={264} height={264} />
          <div style={{ marginTop: 21 }}>
            <Row>
              <Col span={16}>
                <Text
                  variant="body2"
                  style={{
                    color: theme.neutral.color["light-shade-1"],
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {productName}
                </Text>
              </Col>
              {productPrice && (
                <Col span={8}>
                  <Row justify="end">
                    <Col>
                      <Text
                        variant="body0"
                        style={{
                          color: theme.accent.color["secondary-shade-1"],
                        }}
                      >
                        {productPrice}
                      </Text>
                      <Text
                        variant="caption"
                        style={{ color: theme.neutral.color["light-shade-1"] }}
                      >
                        per piece
                      </Text>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            {productDesc && (
              <Row>
                <Col>
                  <Text
                    variant="caption"
                    style={{
                      color: theme.neutral.color["light-shade-1"],
                      marginTop: 12,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {productDesc}
                  </Text>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
      {variant === "outlined" && (
        <div
          style={{
            boxShadow: "-3px 6px 8px -1px rgba(52, 66, 30, 0.08)",
            borderRadius: 10,
            border: "0.5px solid #CCCCCC",
            background: "#fff",
          }}
        >
          <div
            style={{
              width: 264,
              boxShadow: " -3px 6px 8px -1px rgba(52, 66, 30, 0.08)",
              borderRadius: "10px",
              border: "1px solid #ccccc",
              background: "#fff",
            }}
          >
            <img
              src={productImage}
              style={{
                width: 264,
                height: 264,
                objectFit: "contain",
                borderRadius: "10px 10px 0px 0px",
              }}
              alt=""
            />
            <div style={{ marginTop: 21, padding: 8 }}>
              <Row>
                <Col span={16}>
                  <Text
                    variant="subhead"
                    style={{
                      color: theme.primary.color["primary-shade-1"],
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    spanStyle={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {productName}
                  </Text>
                </Col>
                {productPrice && (
                  <Col span={8}>
                    <Row justify="end">
                      <Col>
                        <Text
                          variant="body0"
                          style={{
                            color: theme.primary.color["primary-shade-1"],
                          }}
                        >
                          {productPrice}
                        </Text>
                        <Text
                          variant="caption"
                          style={{ color: "#000", opacity: 0.5 }}
                        >
                          per piece
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              {productDesc && (
                <Row>
                  <Col>
                    <Text
                      variant="caption"
                      style={{
                        color: theme.neutral.color["light-shade-1"],
                        marginTop: 12,
                      }}
                    >
                      {productDesc}
                    </Text>
                  </Col>
                </Row>
              )}
              <Text
                variant="body3"
                style={{
                  color: theme.primary.color["primary-shade-1"],
                  fontWeight: 600,
                  fontFamily: "Raleway",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                spanStyle={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {make}
              </Text>
              <Button
                style={{
                  color: theme.neutral.color["light-shade-1"],
                  background: theme.primary.color["primary-shade-1"],
                  width: "100%",
                  height: 48,
                  borderRadius: 8,
                  marginTop: 12,
                  fontFamily: "Raleway",
                  fontSize: 16,
                  fontWeight: 600,
                }}
                onClick={() => {
                  window.location.href = `/products/product/${id}`;
                }}
              >
                Let's Enquiry now
              </Button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ProductCard;
