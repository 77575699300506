import React from "react";
import Text from "../text";
import Lottie from "react-lottie";
import animationData from "../../assets/taher_loader.json";
import theme from "../../theme";

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Lottie options={defaultOptions} height={150} width={150} />
      <Text
        variant="subhead"
        style={{
          color: theme.primary.color["primary-shade-1"],
          fontFamily: theme.primary.font.primary.name,
        }}
      >
        Hang on for a while...
      </Text>
    </div>
  );
};

export default Loader;
