import { Col, Row, Button } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Image from "../image";
import logo from "../../assets/images/logo.svg";
import theme from "../../theme";
import Text from "../../components/text";
import { Header as AntHeader } from "antd/lib/layout/layout";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import SlideDown from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import useMediaQuery from "@tevhooks/use-media-query";
import clsx from "clsx";

const useStyles = createUseStyles(() => ({
  header: {
    position: "fixed",
    top: 0,
    width: "100%",
    left: 0,
    background: "#0e1a40",
    zIndex: 1000,
    opacity: 1,
    "& .ant-layout-header": {
      background: "#11ffee00 !important",
      lineHeight: 2,
      // height: (props) => (props.mobileMenu ? "auto" : "fit-content"),
      height: "fit-content",
    },
  },

  navItem: {
    color: theme.neutral.color["light-shade-1"],
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    position: "relative",
    paddingBottom: 8,
    "& .navItemBottomLine": {
      width: "0%",
      transition: "width .2s",
      position: "absolute",
      bottom: 0,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
    "&:hover .navItemBottomLine": {
      background: "#FFDC64",
      width: "100%",
      height: 4,
    },
  },

  tableMenu: {
    position: "absolute",
    top: 96,
    background: "#0e1a40",
    left: 0,
    // zIndex: 1000,
    width: "100vw",
    height: "calc(100vh - 96px)",
    transition: "all 0.3s",
    transform: "translateY(-100%)",
    zIndex: -1,
  },

  tablateMenuActive: {
    transform: "translateY(0%)",
  },
}));

const mobileItems = [
  { name: "Home", href: "/" },
  { name: "About Us", href: "/about" },
  { name: "Products", href: "/products" },
  { name: "Contact Us", href: "/contact" },
];

const items = [
  { name: "Home", href: "/" },
  { name: "About Us", href: "/about" },
  { name: "Products", href: "/products" },
];

function Header() {
  const isTablate = useMediaQuery("(max-width: 900px)");
  const [tablateMenu, settablateMenu] = useState(false);

  const paths = window.location.pathname;
  const currentPage = paths;

  useEffect(() => {
    const bodyRef = document.getElementsByTagName("body")[0];
    if (tablateMenu) {
      bodyRef.style.height = "100vh";
      bodyRef.style.overflow = "hidden";
    } else {
      bodyRef.style.height = "auto";
      bodyRef.style.overflow = "visible";
    }
  }, [tablateMenu]);

  const classes = useStyles({ tablateMenu });

  console.log(isTablate);

  return (
    <div style={{ paddingTop: 96 }}>
      <div
        className={classes.header}
        style={{ padding: isTablate ? 0 : "24px 40px 24px 40px" }}
      >
        <AntHeader>
          <div style={{ width: "100%" }}>
            {isTablate && (
              <div>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    height: 96,
                    padding: "24px 40px 24px 40px",
                    background: "#0e1a40",
                  }}
                >
                  <Col>
                    <a href="/">
                      <Image
                        src={logo}
                        width={48}
                        height={48}
                        preview={false}
                      />
                    </a>
                  </Col>
                  <Col>
                    {tablateMenu ? (
                      <CloseOutlined
                        onClick={() => settablateMenu(!tablateMenu)}
                        style={{ color: "#fff", fontSize: 28 }}
                      />
                    ) : (
                      <MenuOutlined
                        onClick={() => settablateMenu((state) => !state)}
                        style={{ color: "#fff", fontSize: 28 }}
                      />
                    )}
                  </Col>
                </Row>
                <div
                  className={clsx([
                    classes.tableMenu,
                    tablateMenu && classes.tablateMenuActive,
                  ])}
                >
                  <SlideDown className={"my-dropdown-slidedown"}>
                    <div style={{ height: 300 }}>
                      <Row
                        gutter={[16, 32]}
                        style={{ marginLeft: 0, marginRight: 0 }}
                      >
                        {mobileItems.map((item) => {
                          console.log(item);
                          return (
                            <Col span={24}>
                              <Row align="middle" justify="center">
                                <Col>
                                  <a href={item.href}>
                                    <Text
                                      variant="body2"
                                      style={{
                                        color:
                                          theme.neutral.color["light-shade-1"],
                                        borderBottom:
                                          currentPage === item.href
                                            ? "4px solid #FFDC64"
                                            : "",
                                        borderBottomLeftRadius: 4,
                                        borderBottomRightRadius: 4,
                                        paddingBottom: 8,
                                        textAlign: "center",
                                        width: "fit-content",
                                      }}
                                    >
                                      {item.name}
                                    </Text>
                                  </a>
                                </Col>
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </SlideDown>
                </div>
              </div>
            )}
            {!isTablate && (
              <div>
                <Row align="middle" justify="space-between" gutter={[8, 16]}>
                  <Col xs={24} sm={24} md={4}>
                    <Row align="middle" justify="center">
                      <Col>
                        <a href="/">
                          <Image
                            src={logo}
                            width={48}
                            height={48}
                            preview={false}
                          />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row align="middle" justify="center" gutter={[8, 16]}>
                      {items.map((item) => {
                        return (
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <Row>
                              <Col>
                                <a href={item.href}>
                                  <Text
                                    variant="body2"
                                    className={classes.navItem}
                                    style={{
                                      borderBottom:
                                        currentPage === item.href
                                          ? "4px solid #FFDC64"
                                          : "",
                                    }}
                                  >
                                    {item.name}
                                    {currentPage !== item.href && (
                                      <div className="navItemBottomLine"></div>
                                    )}
                                  </Text>
                                </a>
                              </Col>
                            </Row>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={4}>
                    <Row>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Button
                          style={{
                            color: theme.primary.color["primary-shade-1"],
                            background: theme.neutral.color["light-shade-1"],
                            width: 146,
                            height: 48,
                            borderRadius: 99,
                            fontFamily: "Raleway",
                            fontSize: 16,
                            fontWeight: 600,
                          }}
                          onClick={() => (window.location.href = "/contact")}
                        >
                          Contact Us
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </AntHeader>
      </div>
    </div>
  );
}

export default Header;
